




















import { Component, Vue } from 'vue-property-decorator';
import { readSettings, dispatchGetSettings, dispatchCreateSettings } from '@/store/admin/accessors';
import { ISetting } from '@/interfaces/setting';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: this.$vuetify.lang.t('$vuetify.key'),
      sortable: true,
      value: 'key',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.value'),
      sortable: true,
      value: 'value',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.type'),
      sortable: true,
      value: 'type',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.commentary'),
      sortable: true,
      value: 'commentary',
      align: 'left',
    },
  ];
  public settings: ISetting[] = [];

  public async setSettings() {
    await dispatchGetSettings(this.$store);
    this.settings = readSettings(this.$store);
  }

  public async mounted() {
    await this.setSettings();
  }

  public async saveSettings() {
    await dispatchCreateSettings(this.$store, this.settings);
    await this.setSettings();
  }
}
