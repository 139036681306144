














































import { Component, Vue } from 'vue-property-decorator';
import { IService } from '@/interfaces';
import {
  readServices,
  dispatchGetServices,
  dispatchDeleteService,
  dispatchDeleteServices,
} from '@/store/admin/accessors';

@Component
export default class AdminUsers extends Vue {
  public selected: IService[] = [];
  public headers = [
    {
      text: this.$vuetify.lang.t('$vuetify.title'),
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.serviceCode'),
      sortable: true,
      value: 'code',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.externalName'),
      sortable: true,
      value: 'external_name',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.images'),
      sortable: false,
      value: 'images_allowed',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.actions'),
      value: 'id',
    },
  ];

  get services() {
    return readServices(this.$store);
  }

  public async mounted() {
    await dispatchGetServices(this.$store);
  }

  public async deleteService(id: number) {
    if (confirm(this.$vuetify.lang.t('$vuetify.deleteServicesConfirmation'))) {
      await dispatchDeleteService(this.$store, id);
      await dispatchGetServices(this.$store);
    }
  }

  public async deleteServices() {
    if (confirm(this.$vuetify.lang.t('$vuetify.deleteServicesConfirmation'))) {
      const selected = this.selected.map((item) => item.id as number);
      await dispatchDeleteServices(this.$store, selected);
      await dispatchGetServices(this.$store);
    }
  }
}
